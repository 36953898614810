<template>
    <div id="addLegend">
      <h2>Insérer des notes</h2>           
      <div>
        <h3>
          📓&nbsp;Ajouter du texte entre deux photos
        </h3>    
        <div class="d-inline-block w-100">    
          <form v-on:submit.prevent="checkMsg($event)">
            <div class="inputDiv">
              <h1>Titre :</h1>        
              <input type="text" required minlength="4" maxlength="32" size="32" v-model="title"/>&nbsp;<span v-if="error === true" class="red">Erreur dans la saisie !</span>
              <h1>Contenu :</h1>
              <textarea maxlength="880" v-model="message" @input="resize($event)"/>
            </div>
            <div class="buttonDiv">
              <button @click="checkMsg($event)" :disabled="stopped">Ajouter</button>
            </div>
          </form>
        </div>
      </div>      
    </div>
</template>

<script>
export default {
  name: 'addLegend',  
  components: {
  },
  props: {
    proprio: String
  },
  data() {         
    return {  
      stopped: false,
      title: '',
      message: '',
      error: null
    }    
  },
  mounted() {    
  },
  watch: {    
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  methods: {  
    resize: function(e) {
      //alert('resize')
      let el = e.target
      if (el.scrollTop != 0) {
        el.style.height = el.scrollHeight + "px"
      }
    },
    allIsUploaded: function() {    
      //alert('All pictures are Uploaded !')
      this.$emit("checkUplOk")
    },
    checkMsg: function(e) {
      //alert('Submit !')
      //this.stopped = true
      if(this.title.trim() != '' || this.message.trim() != '') {
        this.addPix(e)
      }
      else {
        this.error = true
      }
      e.preventDefault()
    },
    addPix: function(e) {
      var apiUrl = "/backend/get/image/legend"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({proprio: this.cleanProprio,title: this.title, message: this.message})
      })
      .then(res => res.json())
      .then(res => {          
        if(res.post != "ok") {
          this.error = true                        
        }
        else if(res.post === "ok") {            
          this.error = false
          let vm = this;
            setTimeout(function (){                            
              vm.$emit("checkUplLeg");
            },500);                        
        }           
      })
    }
  }
}
</script>

<style scoped>
#addLegend {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px dashed #444;
    background-color:#ddd;
    border-radius: 4px;    
}

h1 {
  font-size:1em;
  font-weight: bold;
  text-decoration: underline;
}

h2 {  
  background-size: 20px;
  padding-left: 25px;
  font-size: 1.1rem;
  font-weight: bold;  
  text-align:center;
}

h3 {
  font-size:16px;
  font-weight: normal;
  text-align:center;
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

button:disabled {
  color: #ccc;
}

.buttonDiv {
  text-align: center;
}

.inputDiv {
  text-align: left;
  padding-right:10px;
}

input {
  height:30px;
}

textarea {
  width:100%;
  margin:0 auto;
  /*overflow:hidden;*/
  border-radius: 4px;
}

.red {
  color:darkred;
  font-weight: bold;
}
</style>