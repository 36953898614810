<template>
    <div id="sharePix" class="shadow">
      <Modal v-if="zoomIt === true" @destroyModal="destroyModal" :image="picInModal"/>   
      <Modal v-if="edit === true" @destroyModal="destroyModal" :comment="picInModal" type="edit"/>   
      <AddPix @checkUplOk="checkUplOk" :key="refreshAddPix"/>   
      <AddLegend :proprio="proprio" @checkUplLeg="checkUplOk" :key="refreshAddLegend"/>
      <div v-if="debug===true" v-on:click="saveSort">[ WIP : Sauvegarder classement ]</div>    
      <h2>Les photos&nbsp;<span v-if="isInProgress === true">🔄</span></h2>
      <p>Vous pouvez déplacer les photos comme bon vous semble pour définir leur ordre d'apparition. <b>La première photo de votre installation sera également la vignette qui la représentera.</b></p>      
      <draggable v-model="jsonPix" group="people" @end="saveSort">     
        <div v-for="galerie in jsonPix" v-bind:key=galerie._ID class="d-inline-block imgDiv" :id="galerie.ID">      
          <div class="borderImg shadow">
            <span v-on:click="editIt($event, galerie.ID)" class="edit">📝</span>
            <span v-on:click="modalIt($event, galerie.ID)" class="magnify">🔍</span>
            <span v-on:click="fileDelete($event, galerie.ID)">🗑️</span>
            <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + galerie.ID" :alt="'Installation de ' + galerie.proprio" :class="{ 'show': images.includes(galerie.ID)}" />            
          </div>          
        </div>                 
      </draggable>          
      <p v-if="isInProgress === true" class="text-center">🔄 Chargement en cours !</p>      
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
import AddPix from '@/components/AddPix.vue'
import AddLegend from '@/components/AddLegend.vue'
import Modal from '@/components/Modal.vue'
export default {
  name: 'sharePix',
  components: {    
    VLazyImage,
    AddPix,
    draggable,
    AddLegend,    
    Modal
  },
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null,
      jsonPix: null,
      surfaceSize: null,
      images: [],
      cptImage: 0,
      cptImageInProgress: 0,
      orderPix: [],      
      error: null,
      debug: false,
      isInProgress: true,
      refreshAddPix: 0,
      refreshAddLegend: 100,
      zoomIt: false,
      edit: false,
      picInModal: null
    }    
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  mounted() {    
    this.loadPictures()    
  },
  methods: {
    modalIt: function(e, id) {      
      this.zoomIt = true
      this.picInModal = id
    },
    editIt: function(e, id) {      
      this.edit = true
      this.picInModal = id
    },
    destroyModal: function() {
      this.zoomIt = false
      this.edit = false
      this.picInModal = null
    },
    saveSort: function() {
      let vm = this
      vm.orderPix = []
      vm.jsonPix.forEach(function(galerie) {
        vm.orderPix.push(galerie.ID)
      })
      var apiUrl = "/backend/get/image/sort"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({proprio: this.cleanProprio,order: this.orderPix})
        })
        .then(res => res.json())
        .then(res => {          
          if(res.post != "ok") {
            this.error = true                        
          }
          else if(res.post === "ok") {            
            this.error = false
          }                       
        });
    },
    loadPictures: function() {
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/photos/proprio/' + this.proprio).then(response => {
        this.isInProgress = true
        this.json = response.data.galerie
        this.jsonPix = this.json.pix
        this.images = []
        let vm = this
        vm.jsonPix.forEach(function(galerie){          
          vm.images.push(galerie.ID)
        })
        vm.cptImage = vm.images.length
        vm.cptImageInProgress = vm.images.length
        if(this.json.process === true) {
          setTimeout(function() { vm.loadPictures()}, 600)
          setTimeout(function() { 
            vm.refreshAddPix++,
            vm.refreshAddLegend++
          }, 800)
        }
        setTimeout(function() { vm.isInProgress = false }, 500)          
        })    
    },
    checkUplOk: function() {      
      if(localStorage.installOwner != "true") {
        localStorage.installOwner = "true"   
      }
      this.loadPictures()
    },
    fileDelete: function (e, key) {      
      if(this.images.includes(key)) {        
        this.images.splice(this.images.indexOf(key), 1)
        this.postDeleteFile(key)        
      } 
      this.cptImageInProgress--      
      if( (this.cptImage - this.cptImageInProgress) > 2 ) {
        this.loadPictures()
      }
    },
    postDeleteFile: function(key) {
      var apiUrl = "/backend/get/image/delete"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({proprio: this.cleanProprio, image: key})
        })
        .then(res => res.json())
        .then(res => {          
          if(res.post != "ok") {
            this.error = true                
            return false
          }
          else if(res.post === "ok") {            
            this.error = false            
            return true
          }                       
        });
    },  
    selectedItem: function(e) {
      if ( e === 'Y') {
        return true
      }      
      else {
        return false
      }
    }
  },
}
</script>

<style scoped>
#sharePix {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
}

h2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA+xJREFUeJzt2ttrXFUUBvDfxBZtGhuqBryg1FsUH6yCokWh2oIXUIuK9T9Q9A9Q8QLigwqKrfpgQXxSQUFEq6hYBVGsQr1Qa7UYqLEiaQUhamLSqBkfdgbDsM9ccvY5Z0LywYKwZvLtb30zs8++LJaxtFEriHcAl+PERGNM4Sv8nICrUAziaUyiXkB8gPWlVdMlhnBAMYXPj2lcX1JNXWGH4otvxBiOK6esznCy8MmUZUAdd5ZSWYd4TLnF1zGCvjKKa4c1GFe+AXVsLaG+trhHNcXX8WVe8VnP6H4M4zQc34bjKZySV0gO3IefWrw+gyP4Fr+3I1uPVxX3HK8y/sEubM4q/sG5N1UttIx4DsfML/6RHhBVdrzQKH4TZntAUBVxSw17cImlib01wYkli55YSVWJFRWMOYtRHMSfc7k1OBPrVPChlDHZTOJF3CQUm4VBbMHL+KskbYWST+FRnNSi6CwM4XHF7zILI96NsxdQeDOG8fliM2CHtPPLSjy/WAx4ImHhzdhWgN6kZC+1KWAQd2EnDgk7tZm5v98UTnlaTZI1vNKrBhzA6gzhK3G/sB1txzOOe2X/hAbwQy8asDFD8JAwIXbL97FwrxDDpl4zYGeG0LXCQcRCefcKP5sY3uklA67MEPl2Au7XM7g39ooBIxkCtyYSWBdWh82oCcvpyg3YlmHANwkN+CJjjGd6wYCbI8IuSlh8Iy6IjHNrXt4UO6/vIrmrE/B2wvl9XtIUBoxFcucn4O2E85e8pCkMmIzksh5debA2kpvIS5rCgGMjuekEvM2YiuRW5SVNYUBstTaagLcZP3Y4dldIYcBwJLc7AW8nnLGxu0IKAy6L5D7Cbwm4GziCTyL5DXmJUxhwbSQ3g2cTcDewHf9G8tekIM+7QJnFGRHe1cLvNi//iHg7zDppbrSSrNKezDD3UvlumidwcQb39kTak5BMCL0EMWzGHwvgHMdVGZynS3eFn4SkjtcyxMJ5ujvZ/RTntOB7I6HuZER13NFCdE3YOL2Ho5H/PYp3ha1vq+7Su1NqTn05+jduwPtt3tcv7O4arTVj2C++2puP6/CWxFd6Kb8BdeFK68aUAuewRTAotd7khHWh1eYhadYZfXhYWAcUobUQ0kbsEbrGF4orhFa4IjUWSt6IXcIZYSf9vatwOz4sQ1vZHSLT+Ew4Lzzo/769QZwltOltEN9iF4LlFpmqBVSNPuHruFSxj7CuPqScybCX4rDQl4TQyTHaA6LKil9xoSacKszQVYsrOvbj3ObiG1iBB4TtbdVCU8e0cG7RP7/grF3XCbhNONAYyHJrkWASXwvb9cMVa1nGMpaxjN7Cf7UgA41IkyXRAAAAAElFTkSuQmCC) no-repeat;
  background-size: 20px;
  padding-left: 25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

p {
  margin-left:20px;
  margin-bottom:5px;
  text-align:center;
}

select {
  font-size:0.9em
}

.magnify {
  right:20px !important
}

.edit {
  right:40px !important
}


.borderImg {
  overflow: hidden; 
  position: relative;
  border-radius: 2.5px;  
  border:3px solid #fff;
  background-color:#000;
  margin:2px;  
  cursor:move;
}

.borderImg span {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  padding: 0px 1px;        
  background-color:transparent;
  color: #007bff;     
  height:20px;      
  cursor: pointer;
}

.borderImg img {
  opacity: 0.3;
}

.borderImg .show {
  opacity: 1;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

</style>