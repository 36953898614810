<template>
    <div id="shareDescription" class="shadow">
      <h2>La Description</h2>              
      <div v-if="error === true" class="red">
        Erreur sur la page !
      </div>      
      <div v-if="error === false" class="blue">
        Modifications enregistrées avec succès !
      </div>            
      <div class="contentForm">
        Titre : <input minlength="4" maxlength="128" type="text" :value="titre" size="64" style="margin-bottom:10px"/> <br/>
        Description :<br/>
        <textarea :value="description" />
        <div class="divButton"> 
          <button @click="getDesc" class="shadow button">Envoyer</button>
        </div>
      </div> 
    </div>                   
</template>

<script>
import axios from 'axios'
export default {
  name: 'shareData',
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null,
      surfaceSize: null,
      titre: null,
      description: null,
      error: null      
    }    
  },
  mounted() {
    this.getRestDesc()
  },
  methods: {  
    getRestDesc: function() {
      axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/shareDesc/proprio/' + this.proprio)    
    .then(response => {this.json = response.data.details
      let vm = this
      this.json.forEach(function(details){
          vm.titre = details.Titre
          vm.description = details.Description        
        })                
      })      
    },
    getDesc: function(event) {      
      var zone =  event.target.parentNode.parentNode
      for (var i = 0; i < zone.children.length; i++) {        
        if(zone.children[i].tagName === 'TEXTAREA' && zone.children[i].value.trim() != '') {
          //alert(zone.children[i].value.trim())          
          this.description = zone.children[i].value.trim()
        }
        if(zone.children[i].tagName === 'INPUT' && zone.children[i].value.trim() != '') {
          //alert(zone.children[i].value.trim())
          this.titre = zone.children[i].value.trim()
        }        
      }
      if(this.titre !=null && this.description !=null) {
        this.postDesc()        
      }     
      else {
        this.error = true
      } 
      //event.preventdefault()
    },
    postDesc: function() {
      var apiUrl = "/backend/get/installation/posterDescription"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({proprio: this.cleanProprio,titre: this.titre, description: this.description})
        })
        .then(res => res.json())
        .then(res => {
          this.getRestDesc()
          if(res.post != "ok") {
            this.error = true                        
          }
          else if(res.post === "ok") {            
            this.error = false
          }                       
        });
    }  
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  }
}
</script>

<style scoped>
#shareDescription {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
}

h2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAA6NJREFUeJzt20uIHEUcx/HPDkFQ9+JBBMNG4s1LVFBR2AUhGgx4EB+gZ0EQUVRUEALGPBCEaCRiQEGPelAEUYOoJ0F0FTV68iYrWQIKZqNEEZN4qAzUzvT0a7q7eh8/KNidqvpXfX/VU91VXcOmNrZmcvLmcC1mO+pLrE/wR8U6O7AdJ/Az/qzb+Bw+xPlEab/8gcnSAv6KYpzDcRzA1VXhlxPC72sAfjSdxRFcUiZYypFvAz5OX+DivGBzCeGfrwE/g7cqtnM4L+Cdawg+1l34rWRb/+GqSYHuTwC/dwrwWFcKl3iZNp+bFKRrA/bWAJ3HAxhk5F2EN0u0+21caUuFxn/Fl5W7nK2v8XLFOgs4hkvxFB7GYpT/Lx7ESTybE+d6YTL8ezSj6Ap4p2KHm1TWbH8WB2UP4n75LDuyGumrAUW3us9x2UidGRzNqbM7q6E+GlD2Pv8TrhipuwWfZpRdwTVZjfXNgCoPOUMTRq+Ey4W1QQx/86QG+2RAVfhh+sz4nHCbEvD0x4C68MN0ICPmIQXw9MOAaeGHd4cbyzaY9UCRSvF9fhoN8JqSbH0xoCn4oW7AfWUK9sGApuHhNH4pUzC1AW3B7xIetwuV0oDk8KQzoBfwpDGgN/B0b0Cv4OnWgLbgb1cNfiBaOHVlQJvwi0UFIw2EZfJC/EHb6gv8DF7FQ/GHbRvQJ/gjwjbaKrVpQJ/gD+ORrMy2DGgDfkU9+EN4bFKBNgxoC36X6vAv4om8Qm0Y8Du+azBeXfgXhO3z0mpyQ2SAJ4W9+mk2N07hpioQF/RMQdx74462oXN4SXiTc6JmjDojP1Rp05owYF7o5N3GX3IuXujM8Yoxp4GvpCYMOCbswb0n7MNvH8lfxq34qmS8OrN9bTVhQHyGaCd+FOaTWKdwB34oiDWE/6aBfpVSG3PALN42ftZnRXgltTShXufwtPskuAevWG3CSeFAwz8jZZPA0/5a4FHh7E+s7/F49H8yeLpZDe4xPie8jo8lhqe7/YA3rD6vd15YmSWFpzsDZoVRj+eDJYnh6XZLbKcwAfZKXW+KPt1xe4Xq0oDTwgKpV6pySmySpjnkmFyp3w0m16YBqTuQWhvegCqT4DzebasjDeuWsgWrGLAV91TvS7+14b8CsQGja/T1rDPDP2IDirar1pMmsn5kun38tZDez3Nmm9WHi9dbWhJ+XpOrOXzQg862MfJj8HkLma24TsFv7daAzgjf+eXUHdlUH/U/+ggB0rZwsxwAAAAASUVORK5CYII=) no-repeat;
  background-size: 20px;
  padding-left: 25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

textarea {
  width:100%;
  height:20em;
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.divButton {
  text-align:right;
}

.contentForm {
  padding:5px;
}

.red{
  color:red;
}

.blue {
  color: #007bff;
}

input {
  width:100%;
}

</style>