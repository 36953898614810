<template>
    <div id="shareData" class="whitebg shadow">
      <h2>Les données</h2>        
      <div class="red" v-if="error != null">{{ error }}</div>
      <form v-on:change="checkForm" id="formData">
        <div v-for="details in json" v-bind:key=details.ID>          
          <p>
            <label>Type d'Installation :</label>&nbsp; 
            <input type="hidden" name="proprio" :value="cleanProprio"/>
            <select name="Dedier" id="roomType-select">
              <option v-for="selectRoomType in details.selectRoomType" v-bind:key=selectRoomType.range :value="selectRoomType.range" :selected="selectedItem(selectRoomType.selected)">{{ selectRoomType.texte }}</option>
            </select>
          </p>    
          
          <p>
            <label>Budget :</label>&nbsp; 
            <select name="Budget" id="budget-select">
              <option v-for="selectBudget in details.selectBudget" v-bind:key=selectBudget.range :value="selectBudget.range" :selected="selectedItem(selectBudget.selected)">{{ selectBudget.texte }}</option>
            </select>
          </p>
          
          <p><label>Configuration HP :</label>&nbsp;
            <select name="speaker" id="speaker-select">
              <option v-for="selectSpeaker in details.selectSpeaker" v-bind:key=selectSpeaker.range :value="selectSpeaker.range" :selected="selectedItem(selectSpeaker.selected)">{{ selectSpeaker.range }}</option>
            </select>
            <b>&nbsp;.&nbsp;</b>
            <select name="sub" id="sub-select">
              <option v-for="selectSub in details.selectSub" v-bind:key=selectSub.range :value="selectSub.range" :selected="selectedItem(selectSub.selected)">{{ selectSub.range }}</option>
            </select>
            <b>&nbsp;.&nbsp;</b>
            <select name="atmos" id="atmos-select">
              <option v-for="selectAtmos in details.selectAtmos" v-bind:key=selectAtmos.range :value="selectAtmos.range" :selected="selectedItem(selectAtmos.selected)">{{ selectAtmos.range }}</option>
            </select>
          </p>          
          <p><label>Surface  :</label>&nbsp;<input v-model="surfaceSize" type="range" id="surfaceInput" name="surface" min="4" max="120" :run="setValue(details.surface)"/>&nbsp;
            <span>{{ surfaceSize }} m²</span>
          </p>      
          <p class="explain">
            Les modifications sont enregistrées automatiquement.
          </p>
        </div>      
      </form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'shareData',
  props: {
    proprio: String
  },
  data() {         
    return {
      json: null,
      surfaceSize: null,
      error: null      
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/shareDetails/proprio/' + this.proprio)
    .then(response => (this.json = response.data.details))    
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  methods: {
    setValue(value) {
      if(this.surfaceSize === null) {
        this.surfaceSize = value
      }      
    },    
    serializeArray: function (form) {
      // Setup our serialized data
      var serialized = [];
      // Loop through each field in the form
        for (var i = 0; i < form.elements.length; i++) {
        var field = form.elements[i];
        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
          // If a multi-select, get all selections
          if (field.type === 'select-multiple') {
            for (var n = 0; n < field.options.length; n++) {
              if (!field.options[n].selected) continue;
                serialized.push({
                  name: field.name,
                  value: field.options[n].value
                });
              }
            }
          // Convert field data to a query string
          else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized.push({
              name: field.name,
              value: field.value
            });
          }
        }
      return serialized;
    },
    checkForm: function (e) {
      this.submit = null
      var apiUrl = "/backend/get/form/changeData"      
      fetch(apiUrl,{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: JSON.stringify( this.serializeArray(document.getElementById("formData")))
      })
      .then(res => res.json())
      .then(res => {        
        if (res.error != "null") {
          this.error = res.error
        } else {    
          this.error = null
        }        
      })
      e.preventDefault()        
    },
    selectedItem: function(e) {
      if ( e === 'Y') {
        return true
      }      
      else {
        return false
      }
    }
  },
}
</script>

<style scoped>
#shareData {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
}

h2 {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAACRUlEQVRYhe2WsWsUQRTGf55oxERrI8GAnoJgEExzBixMIYKeSP4CLcVKFJOAWogI+k94NgGL9Emh6eW0SKNoEW2VKHpGMEHXYr9hnuPM3m7OKIofDLP7vTffe3tv5s3Bf/yleANkwXj7u4IPR4K7MVxVrLaOBEY1zwGbNOYD24YlsAU4peenhn+i+bR8esZeoA1cAw6IOwu8xv/cE8Z/wvCvgDPi90ujLc3SmOXH2r40z8+Ai0C/8e8X99z4vQg0ZssGH9eCj0ALeK/3L8Al8pqnUAMuyzfT2pa0MmkXYjOwKOcpcVvJa3u07BcAY0AT6NP7tDQXFSOJC3JcA4YK/JrAAvBJ45GSTGFImpliJHEQWMLXsB7xuU26D9yK+Nfxe2hJMQqxi3zXZuQdzybRxO+Hq8BujUl83e0vUcd3zba0S2EAWNbCEcMviJuMrJmS7aHhRsQtS7MSVrR4p+E64gYj/oP40+OwQ9xKKkhRJ8w022P3VfNqxH8t8LHP20kc31QCA+Q1Bdhj+Lbm85E15zQ/Ntxn/H2RhQtSKNqEJ/Gb8IjhD4v7BpwI9Gp0OfsW4THcF/G5AdyJiN8ErkeC3wNmyibhGtEqxY2ojLizux5RKgnbiqfF9ZGf/7EK4tbeAT5UScJdRh3gPv4ycl2um3hoPwY0jN+VbgnAz9fx3UTwUHwmYndoAA+AbWUSiP0hiQW34i6JmL1n1MjvddfVjkd8RoF3Bfaegqe+PESji31dwVsUf/mG4pAC//KaVsH4nwz+7+M75xnOgQ+MGAoAAAAASUVORK5CYII=) no-repeat;
  background-size: 20px;
  padding-left: 25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

input, select {
  font-size:1em;
  height:30px;
  vertical-align: middle;
}

.red {
  color:red;
  font-weight: bold;
}

.explain {
  color :#007bff;
  margin-top:10px;
}

label {
  width:110px;
}
</style>