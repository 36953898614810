<template>  
    <div v-if="json != null && this.destroyed === false" class="element">                    
      <div v-for="materiel in json" v-bind:key=materiel._id>     
        <label v-if="materiel.baseCat != null">Catégorie</label>                           
        <select v-if="materiel.baseCat != null" name="categorie" @change="getCat($event)">
          <option v-for="categorie in materiel.baseCat" v-bind:key=categorie._id :value="categorie.ID" :selected="selectedItem(categorie.selected)">{{ categorie.Texte }}</option>
        </select>    
        <label v-if="materiel.baseFonc != null">Fonction</label>                           
        <select v-if="materiel.baseFonc != null" name="fonction">
          <option v-for="fonc in materiel.baseFonc" v-bind:key=fonc._id :value="fonc.ID" :selected="selectedItem(fonc.selected)">{{ fonc.Texte }}</option>
        </select>                     
        <!-- MSG : Refacto 16:18 -->
        <div class="AutoInput d-inline-block" v-if="materiel.marque != null">
          <label>Marque</label><AutocompleteInput v-for="marque in materiel" v-bind:key=marque._id :strValue="marque.Texte" type="marque"/>
        </div>
        <div class="AutoInput d-inline-block" v-if="materiel.designation != null">
          <label>Désignation</label><AutocompleteInput v-for="designation in materiel" v-bind:key=designation._id :strValue="designation.Texte" type="designation"/>
        </div>
      </div>
      <div class="pointer">      
        <span @click="deleteLine">🗑️</span>
      </div>
    </div>      
</template>

<script>
import axios from 'axios'
import AutocompleteInput from '@/components/AutocompleteInput.vue'
export default {
  name: 'matosLine',  
  components: {
    AutocompleteInput
  },
  props: {
    line: String    
  },
  data() {         
    return {
      json: null,
      destroyed: false,
      cat: null
    }    
  },
  mounted() {    
    this.getLine()
  },
  methods:{ 
    getCat(e) {
      //alert(e.target.value)
      this.cat = e.target.value
      this.getLine()
    },
    getLine: function() {
      var urlRest = ''
      if(this.cat !== null ) {
        this.json = null
        urlRest = this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/materiel/id/' + this.line + '/' + this.cat
      }
      else {
        urlRest = this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/materiel/id/' + this.line 
      }
      axios.get(urlRest)
        .then(response => (this.json = response.data.materiel))          
    },
    deleteLine: function() {
      this.destroyed = true
    },
    selectedItem: function(e) {
      if ( e === 'Y') {
        return true
      }      
      else {
        return false
      }
    }
  }
}
</script>

<style scoped>

.pointer {
  text-align:right;
}

.pointer span {
  cursor:pointer;
}

select {
  height:30px;
  width:170px;
  margin-right:2px;
  margin-top:2px;
}

div {
  box-sizing: border-box;
}

label {
  width:80px;
  color:#007bff;
}

.element {
  width:260px;
}
</style>