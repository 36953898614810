<template>
  <div id="shareMatos" class="whitebg shadow">
    <h2>Matériel</h2>
    <div v-if="retour === false" class="red">     
      Erreur lors de l'enregistrement !            
    </div>
    <div v-if="retour === true" class="green"> 
      Opération réalisée avec succès !
    </div>
      <form name="shareMatosForm" id="shareMatosForm" v-on:submit="submitForm" method="post" autocomplete="off">        
        <input type="hidden" name="proprio" :value="cleanProprio"/>        
        <div class="d-inline-block" v-for="materiel in json" v-bind:key=materiel.ID>                    
          <MatosLine v-if="materiel.ID" :line="materiel.ID"/>
        </div>
        <div class="d-inline-block matosElement" v-for="n in emptyLine" v-bind:key=n._id>          
          <MatosLine line="newLine"/>
        </div>
        <div class="addClass">
          <span class="pointer" @click="addLine">Ajouter du matériel ➕</span>&nbsp;<span class="pointer" @click="submitForm">Sauvegarder 💾</span>
        </div>
      </form>
  </div>
</template>

<script>
import axios from 'axios'
import MatosLine from '@/components/MatosLine.vue'
export default {
  name: 'shareMatos',
  components: {
    MatosLine
  },
  props: {
    proprio: String    
  },
  data() {         
    return {
      json: null,
      sectionInProgress: null,
      emptyLine: 1,
      retour: null
    }    
  },
  mounted() {
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/materiel/proprio/' + this.proprio)
    .then(response => (this.json = response.data.materiel))    
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  methods:{    
    addLine: function() {      
      this.emptyLine++     
    },
    submitForm: function(e) {
      var apiUrl = "/backend/get/form/materiel"
      fetch(apiUrl,{
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( this.serializeArray(e.target.parentNode.parentNode))
      })
      .then(res => res.json())
      .then(res => {          
        this.retour = res.response
      })
    },
    serializeArray: function (form) {
      // Setup our serialized data
      var serialized = [];
      // Loop through each field in the form
        for (var i = 0; i < form.elements.length; i++) {
        var field = form.elements[i];
        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
          // If a multi-select, get all selections
          if (field.type === 'select-multiple') {
            for (var n = 0; n < field.options.length; n++) {
              if (!field.options[n].selected) continue;
                serialized.push({
                  name: field.name,
                  value: field.options[n].value
                });
              }
            }
          // Convert field data to a query string
          else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized.push({
              name: field.name,
              value: field.value
            });
          }
        }
      return serialized;    
    }
  }
}
</script>

<style scoped>
#shareMatos {
    text-align:left;
    padding:10px;
    margin:10px;
    border:1px solid #eee;
}
h2 {
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABE0lEQVRIie2Vvw7BQBzHP0RMEovB7AUsJnSy8AA2s1fwCnajd7CZJQSPIcFisAkJ8WdoLznX07tWa5B+ku/Q5O73+fXXawspdlSAtZfKr6QOcACeXo5AK2lpH7hKUpEbMEhCmANGGqGaMZCPS1oCZhZSkSVQ/lZaBTYhpCJ7oBZV2gVOEaQiF6AXRpjBPSj3L6QiD2AIZE3SAjCJQahmChSDxKsEpCIrWaSO4KxppoE7/jBxNHV0tX3InYL7iojrhU0BTY03jA/d46EUTAy522aE/Q6GO84EiOPE57Ed9c+Qx1SPsL+JYdQ2YkhPtZn0VPv4JN5b7G2g/yar7OzbgY634dOfZi6tXQSs2wLtMOKU/+EFrTnBHM14D8sAAAAASUVORK5CYII=) no-repeat;
  background-size:20px;
  padding-left:25px;
  font-size: 1.1rem;
  font-weight: bold;  
}

h3 {
  padding-left:10px;
  font-size: 1rem;
  font-weight: bold;
  color:#007BFF;
}

p {
  margin-left:20px;
  margin-bottom:5px;
}

.pointer {
  cursor:pointer;
  display:inline-block;
  width:49%;
  transition-property: font-size;
  transition-duration: .5s;
  transition-delay: .5s;
  font-weight:bold;
  color:#007BFF;
  animation: blinker 1s linear 5;
}

.pointer:hover {
  font-size:20px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.zoom {
  font-size:20px;
}

.addClass {
  text-align:center; 
  margin: 0 auto;
  margin-top:10px;
}

.red {
  font-weight: bold;
  color:red;
}

.green {
  font-weight: bold;
  color:darkgreen;
}



</style>