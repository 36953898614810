<template>
  <div class="shareInstall container-fluid min-vh-100">
    <vue-headful
      title="Install-HC - Partager mon Installation Home-Cinema"
      description="Page permettant aux membres de présenter simplement et rapidement leur Installation Home-Cinema"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>
    <div class="shareInstall">
      <h1>Partager mon Installation Home-Cinema</h1>                  
      <div v-if="login">
        <h2>Pour partager votre Installation Home-Cinema avec les membres du site, c'est simple, il suffit juste d'effectuer les étapes suivantes :</h2>
        <a href="#" id="pix" @click="getTask">J'ajoute et gère mes Photos</a>*<br>
        <SharePix v-if="task==='pix'" :proprio="login"/>
        <a href="#" id="data" @click="getTask">Je saisis mes Données</a><br>
        <ShareData v-if="task==='data'" :proprio="login"/>        
        <a href="#" id="desc" @click="getTask">Je décris mon Installation</a><br>
        <ShareDescription v-if="task==='desc'" :proprio="login"/>
        <a href="#" id="matos" @click="getTask">Je liste mon Matériel</a><br>
        <ShareMatos v-if="task==='matos'" :proprio="login"/>        
        <i>* partie obligatoire pour que l'Installation soit validée</i><br><br>
        <router-link :to="{path: '/presentation/' + login }">Accès au Résultat</router-link>
      </div>
      <div v-else>
        <img src="https://media.giphy.com/media/Vzk5PFo9iH5AEustmv/source.gif"/><br>      
      </div>
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
import ShareData from '@/components/ShareData.vue'
import SharePix from '@/components/SharePix.vue'
import ShareDescription from '@/components/ShareDescription.vue'
import ShareMatos from '@/components/ShareMatos.vue'
export default { 
  name: 'shareinstall',
  components: {
    Menu,
    InstallHC,
    ShareData,
    SharePix,
    ShareDescription,
    ShareMatos
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: '',
      task: null,
      login: null
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },
  methods: {
    getTask: function(e) {
      this.task = e.target.id
      e.preventDefault()
    }   
  },
  beforeCreate() {  
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/form/validateAccount').then(response => {
      this.json = response.data.account
      if(!this.json.error) {
        this.login = this.json.login.replace(/ /g,'___')
      } 
    })
  },          
  mounted() {    
    this.actualSize = window.innerHeight    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.actualSize = (window.innerHeight-117)
      });
    })   
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.shareInstall {
  margin:0 auto 20px 0;  
  width:100%;    
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

h2 {
  font-size:1em;
}


a {
  font-weight: bold;
  font-size:1.1rem;
}

a:hover
{
  color: #007bff;
  text-decoration:none;  
}

</style>